import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { SelectField } from '@unite-us/ui';
import { useFindNetworksByName } from 'src/components/Backoffice/api/hooks/v1/networkHooks';

const NetworkTypeaheadField = ({ isEditing }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: networks, isLoading, refetch } = useFindNetworksByName(searchTerm);

  const loadOptions = (query) => {
    setSearchTerm(query);
    refetch();
    return networks;
  };

  return (
    <Field
      name="networks"
      validate={(selectedItems) => {
        if (!isLoading && (!isEditing && selectedItems?.length === 0)) {
          return 'Required';
        }
        return undefined;
      }}
    >
      {({ input, meta }) => {
        const mergedOptions = [...networks];
        if (input.value) {
          input.value.forEach((selected) => {
            if (!mergedOptions.some((option) => option.id === selected.id)) {
              mergedOptions.push(selected);
            }
          });
        }
        return (
          <SelectField
            loadOptions={loadOptions}
            options={mergedOptions}
            placeholder="Select network ids..."
            id="networks-field"
            loadingText="Searching..."
            label="Networks"
            multiple
            valueKey="id"
            labelKey="name"
            searchThreshold={0}
            position="top"
            required={!isEditing}
            className={`md:col-span-6 xl:col-span-6 px-0 ${meta.error && meta.touched ?
              'ui-select-field--has-error has-error ui-form-field--has-error has-error' :
              ''
              }`}
            dataTestElement="network_ids_select"
            error={meta.error && meta.touched ? meta.error : ''}
            {...input}
          />
        );
      }}
    </Field>
  );
};

NetworkTypeaheadField.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default NetworkTypeaheadField;
