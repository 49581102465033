import { useFind } from 'api/APIHooks';
import { useFeatureFlag } from 'common/hooks';

export const useServiceAuthorizationStatusCount = (state, payerId) => {
  const includeInternalNonConsentFF = useFeatureFlag('PAYS-7267-include-internal-nonconsent');

  const { data } = useFind(
    'service_authorization',
    {
      state,
      'insurance.plan.payer': payerId,
      'person.consent.state': 'accepted',
      ...(includeInternalNonConsentFF ? { include_internal_nonconsent: true } : {}),
    },
    {
      page: {
        number: 1,
        size: 1,
      },
      queryConfig: {
        enabled: !!payerId,
        placeholderData: undefined,
      },
    },
  );

  const count = data?.data?.meta?.page?.total_count;
  return count === 0 ? null : count;
};
