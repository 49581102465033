// eslint-disable-next-line max-len
export const PHI_INFO_MESSAGE = 'Include only the minimum information necessary to provide the requested services. Do not include sensitive information (e.g., interpersonal violence, substance use, or legal services information).';
// eslint-disable-next-line max-len
export const CFR_PART_2_CASE_MESSAGE = 'If this case is for a 42 CFR Part 2 covered service, Part 2 prohibits the unauthorized re-disclosure of these records.';
// eslint-disable-next-line max-len
export const CFR_PART_2_REFERRAL_MESSAGE = 'If this referral is for a 42 CFR Part 2 covered service, Part 2 prohibits the unauthorized re-disclosure of these records.';

// error messages from service_authorization_cap_rules
// eslint-disable-next-line max-len
export const UTILIZATION_ERROR = 'The client is already receiving this service through either a passthrough or non-passthrough service within this program group.';
// eslint-disable-next-line max-len
export const DATES_ERROR = 'Clients can only receive services from this program for 30 days or until their social coverage ends, whichever comes first.';

// copyright texts
export const LOINC_TEXT = 'This material contains content from LOINC (http://loinc.org). LOINC is copyright © ' +
           'Regenstrief Institute, Inc. and the Logical Observation Identifiers Names and Codes (LOINC) ' +
           'Committee and is available at no cost under the license at http://loinc.org/license. ' +
           'LOINC® is a registered United States trademark of Regenstrief Institute, Inc.';
export const SNOMED_TEXT = 'Some material in the UMLS Metathesaurus is from copyrighted sources of the respective ' +
          'copyright holders. Users of the UMLS Metathesaurus are solely responsible for compliance with any ' +
          'copyright, patent or trademark restrictions and are referred to the copyright, patent or trademark ' +
          'notices appearing in the original sources, all of which are hereby incorporated by reference.';
