import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import {
  Button,
  InputField,
} from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import { browserHistory } from 'common/utils/browserHistory';
import { BackButton } from 'common/buttons';
import { Spinner } from 'common/spinners';
import { PRD_FE_DOMAIN } from 'src/config/env/env.config';
import NetworkTypeaheadField from './NetworksTypeaheadField';

const namespace = 'prd-editor-form';

export const PrdForm = ({
  prdId, onSubmit, initialValues, showPending,
}) => {
  const isEditing = !isEmpty(prdId);
  const title = isEditing ? 'Edit PRD' : 'Create New PRD';
  const onCancel = useCallback(() => {
    if (isEditing) {
      browserHistory.push({ pathname: `/backoffice/prds/${prdId}` });
    } else {
      browserHistory.push({ pathname: '/backoffice/prds' });
    }
  }, [prdId, isEditing]);

  return (
    <div id="prd_form" className="bg-dark-fill-blue">
      <div className="flex p-4 justify-start items-center bg-white">
        <div>
          <BackButton navigateTo={onCancel} />
        </div>
        <h1 className="pl-6 w-9/12 font-bold">{title}</h1>
      </div>
      {showPending && <Spinner />}
      {!showPending && (
        <div className="p-6">
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                data-testid="prd-form"
                className={
                  'grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12 ' +
                  'pl-10 pt-6 bg-light-border-grey border border-solid rounded overflow-hidden border-dark-border-blue'
                }
              >
                <Field
                  name="customer_name"
                  validate={composeValidators(validations.isRequired)}
                >
                  {(props) => (
                    <InputField
                      className="md:col-span-6 xl:col-span-6 px-0"
                      id={`${namespace}__customer_name-field`}
                      label="Customer Name"
                      required
                      placeholder="Type Customer Name"
                      {...props}
                    />
                  )}
                </Field>
                <div className="md:col-span-6 px-0" />
                <Field
                  name="domain"
                  validate={(value) => {
                    if (value) {
                      if (/^-|-$/.test(value)) {
                        return 'Domain cannot start or end with a hyphen';
                      }
                      if (/[^a-zA-Z0-9-]/.test(value)) {
                        return 'Domain cannot contain special characters';
                      }
                      if (value.length > 63) {
                        return 'Domain cannot be longer than 63 characters';
                      }
                    }
                    return validations.isRequired(value);
                  }}
                >
                  {(props) => (
                    <>
                      <InputField
                        className="md:col-span-4 xl:col-span-4 px-0"
                        id={`${namespace}__domain-field`}
                        label="Domain Name"
                        required
                        placeholder="Type Domain Name"
                        {...props}
                      />
                      <div className="md:col-span-2 xl:col-span-2 px-0 pt-8">
                        <span>.{PRD_FE_DOMAIN}</span>
                      </div>
                    </>

                  )}
                </Field>
                <div className="md:col-span-6 px-0" />
                <Field
                  name="customer_code"
                >
                  {(props) => (
                    <InputField
                      className="md:col-span-6 xl:col-span-6 px-0"
                      id={`${namespace}__customer_code-field`}
                      label="Customer Code"
                      placeholder="Type Customer Code"
                      {...props}
                    />
                  )}
                </Field>
                <div className="md:col-span-6 px-0" />
                <NetworkTypeaheadField isEditing={isEditing} />
                <div className="md:col-span-6 px-0" />
                <div className="flex items-start mt-8 pb-9">
                  <Button
                    data-test-element="cancel-create-prd-btn"
                    label="Cancel"
                    onClick={onCancel}
                  />
                  <Button
                    className="ml-6"
                    data-test-element={isEditing ? 'update-prd-btn' : 'create-prd-btn'}
                    label={isEditing ? 'Update' : 'Create'}
                    primary
                    type="submit"
                  />
                </div>
              </form>
            )}
          />
        </div>
      )}
    </div>
  );
};

PrdForm.propTypes = {
  prdId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  showPending: PropTypes.bool,
};

PrdForm.defaultProps = {
  prdId: '',
  initialValues: {},
  showPending: false,
};

export default PrdForm;
