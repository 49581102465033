import { removeOptionalParamsFromPath } from 'common/utils/Navigation';
import _ from 'lodash';

function getIcon(path) {
  switch (path) {
    case 'payers':
      return 'Benefits';
    case 'fee_schedules':
      return 'IconCalendar';
    case 'prds':
      return 'V2Network';
    default:
      return 'IconCube';
  }
}

function routesToNavConfig({
  route, path,
} = {}) {
  const routeTabs = _.reduce(_.get(route, 'childRoutes', []), (acc, childRoute) => {
    const newPath = removeOptionalParamsFromPath(`/${path}/${childRoute.path}`);
    if (childRoute.label) {
      return [...acc, {
        label: childRoute.label,
        path: newPath,
        icon: getIcon(childRoute.path),
      },
      ];
    }
    return acc;
  }, []);
  return routeTabs;
}

export default routesToNavConfig;
