/* eslint-disable query-config/enforce-query-config */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFind, useFindRecord, usePopulate, usePopulateMemo } from 'src/api/APIHooks';
import AsideColumn from 'src/components/Dashboard/components/AsideColumn';
import ServiceCaseOptions from 'src/components/Cases/components/Detail/ServiceCaseOptions';
import ServiceCaseReferralHistory from 'src/components/Cases/components/Detail/ServiceCaseReferralHistory';
import ServiceCaseFeeScheduleServicesProvided from
'src/components/Cases/components/Detail/ServiceCaseFeeScheduleServicesProvided';
import CopyrightText from 'common/Disclaimer/CopyrightText';
import { editable } from 'common/utils/Permission/Permission';
import {
  hasPaymentsUserAccess,
  restrictToSubmissionContext,
  hasReferralNoteSection,
  pays7390CopyrightText,
} from 'src/common/utils/FeatureFlags/flags';
import { fetchServiceCase } from 'actions/Case/Contact/Group';
import { attachCaseDocuments } from 'actions/Document/Contact/Group';
import { renameCaseDocument, removeCaseDocument } from 'actions/Document/Case/Contact/Group';
import fetchProviderIsSensitive from 'actions/Group/fetchProviderIsSensitive';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import callOrLog from 'common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import { Spinner } from 'common/spinners';
import classNames from 'classnames';
import { getEnumsFromState } from 'common/utils/Enums';
import { SNOMED_TEXT } from 'common/messageConstants';
import FormSubmissions from 'src/components/FormSubmissions/FormSubmissions';
import Notes from 'src/components/Notes/Notes';
import ServiceCaseInteractions from 'src/components/Cases/components/Detail/ServiceCaseInteractions';
import { TrackerContext } from '@unite-us/client-utils';
import ReferralNotes from 'src/components/Notes/ReferralNotes';
import '../../stylesheets/cases.scss';

export const CaseDetailsView = (props) => {
  const {
    caseAssessments,
    caseId,
    contactId,
    currentProvider,
    currentProviderType,
    employeeProviderId,
    enums,
    groupId,
    history,
    isCoordinationGroup,
    refetch,
    isFetching,
    serviceCase,
    showContactColumn,
    type,
    usePaymentsUserRole,
    referralNoteSection,
    currentProviderId,
    showCopyrightText,
  } = props;

  usePopulate('fee_schedule_program', 'fee_schedule_program', _.get(serviceCase, 'program'));
  const serviceCaseProgramId = _.get(serviceCase, 'program.id');
  const { data: serviceCaseProgramResponse } = useFindRecord('programs', serviceCaseProgramId, {
    queryConfig: {
      enabled: !!serviceCaseProgramId,
    },
  });
  const serviceCaseProgram = _.get(serviceCaseProgramResponse, 'data.data', {});
  const isFSP = !!_.get(serviceCaseProgram, 'fee_schedule_program.id');
  const [isSensitive, setIsSensitive] = useState(false);
  const [showInteractions, setShowInteractions] = useState(false);
  const eventTracker = useContext(TrackerContext);
  const serviceCaseGroupId = _.get(serviceCase, 'group.id');
  const showNotes = (employeeProviderId === serviceCaseGroupId || currentProviderType === 'coordination_center');
  const provider = serviceCase.provider;
  const referralsQuery = useFind(
    'referral',
    { case: serviceCase.id },
    { queryConfig: { enabled: Boolean(serviceCase.id) } },
  );
  const { data: referralsData } = referralsQuery;
  const referrals = _.get(referralsData, 'data.data', {});

  const serviceAuthorizationId = serviceCase.service_authorization?.id;
  const serviceAuthorizationQuery = useFindRecord('service_authorization', serviceAuthorizationId, {
    queryConfig: {
      enabled: !!serviceAuthorizationId,
      placeholderData: undefined,
    },
  });
  const isServiceAuthorizationLoaded = serviceAuthorizationQuery.isSuccess;
  const serviceAuthorization = serviceAuthorizationQuery.data?.data?.data || {};
  const fspQuery = usePopulate('fee_schedule_program', 'fee_schedule_program', serviceAuthorization);
  usePopulateMemo(serviceAuthorizationQuery.data, [fspQuery]);

  const getCaseIsSensitive = async (kase = {}) => {
    // Check that the group associated with the case is sensitive.
    const caseGroupId = _.get(kase, 'provider.id', '');
    const caseGroupIsSensitive = caseGroupId ?
      await fetchProviderIsSensitive(caseGroupId) :
      false;

    // Find the referral that was sent to the group associated with the case
    // and check if its referred_by_group.id is sensitive.
    const caseReferral = _.find(_.get(kase, 'referrals', []), ((referral = {}) => (
      _.get(referral, 'referred_to_group.id', '') === caseGroupId
    )));
    const referredByGroupId = _.get(caseReferral, 'referred_by_group.id', '');
    const referralGroupIsSensitive = referredByGroupId ?
      await fetchProviderIsSensitive(referredByGroupId) :
      false;

    const sensitive = caseGroupIsSensitive || referralGroupIsSensitive;
    setIsSensitive(sensitive);
  };

  const fetchProvidersPrograms = (kase) => {
    const providerId = _.get(kase, 'provider.id');
    const programId = _.get(kase, 'program.id');
    props.fetchGroupsPrograms(providerId, {
      ids: programId,
      include: 'fee_schedule_program',
    });
  };

  const fetchCase = () => {
    props.fetchServiceCase(groupId, contactId, caseId, currentProvider)
      .then((payload) => {
        // Check if the group associated with the case is sensitive.
        const kase = _.get(payload, 'data.data');
        getCaseIsSensitive(kase);
        if (!kase) {
          return {};
        }
        return fetchProvidersPrograms(kase);
      });
  };

  const attachDocuments = (documents = {}) => {
    const { attachedDocuments, uploadedDocuments } = documents;
    return props.attachCaseDocuments({
      attachedDocuments,
      caseId,
      contactId,
      groupId,
      uploadedDocuments,
    }).then(() => {
      fetchCase();
      callOrLog(() => eventTracker(
        SERVICE_CASE.documentUploaded,
        null,
        {
          serviceCase,
        },
      ));
    });
  };

  const removeDocument = (doc = {}) => (
    props.removeCaseDocument({
      caseId: serviceCase.id,
      contactId: _.get(serviceCase, 'person.id'),
      groupId,
      documentId: doc.id,
    })
  );

  const renameDocument = (doc = {}) => (
    props.renameCaseDocument({
      caseId: serviceCase.id,
      contactId: _.get(serviceCase, 'person.id'),
      documentId: doc.id,
      groupId,
      title: doc.title,
    })
  );

  useEffect(() => {
    fetchCase();
  }, [currentProvider, caseId]);

  useEffect(() => {
    if (refetch && !isFetching) {
      props.fetchServiceCase(groupId, contactId, caseId);
    }
  }, [refetch, isFetching]);

  const gridClass = () => classNames({
    'col-md-9': showContactColumn,
    'col-md-12': !showContactColumn,
  });
  const { editProvidedServiceId, isEditing } = history;
  const scrollInto = isEditing;
  if (_.isEmpty(serviceCase) || isFetching) {
    return (
      <div className="service-case--loading">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="case-detail-view row">
      <div className={gridClass()}>
        <ServiceCaseOptions
          groupId={groupId}
          groupIsSensitive={isSensitive}
          isCoordinationGroup={isCoordinationGroup}
          isServiceAuthorizationLoaded={isServiceAuthorizationLoaded}
          serviceAuthorization={serviceAuthorization}
          serviceCase={serviceCase}
          onAttachDocuments={attachDocuments}
          onRemoveDocument={removeDocument}
          onRenameDocument={renameDocument}
          currentProviderId={currentProviderId}
          type={type}
        />
        {
          usePaymentsUserRole && isFSP ? (
            <ServiceCaseFeeScheduleServicesProvided
              caseId={caseId}
              className="mt-6"
              contactId={contactId}
              editProvidedServiceId={editProvidedServiceId === undefined ? '' : editProvidedServiceId}
              isCoordinationGroup={isCoordinationGroup}
              editing={isEditing}
              scrollInto={scrollInto}
              groupId={groupId}
              serviceCase={serviceCase}
              serviceAuthorization={serviceAuthorization}
            />
          ) : null
        }
        <div className="mt-12">
          <FormSubmissions
            context={serviceCase.id}
            contextType="case"
            providerId={currentProviderId}
            serviceId={_.get(serviceCase, 'service.id')}
            editable={editable(serviceCase, type, currentProviderId)}
          />
        </div>
        {
          showInteractions ? (
            <ServiceCaseInteractions
              caseAssessments={caseAssessments}
              enums={enums}
              contact={serviceCase.person}
              serviceCase={serviceCase}
              isCoordinationGroup={isCoordinationGroup}
              groupId={groupId}
              cancelFunction={() => { setShowInteractions(false); }}
              styles={{ position: { marginTop: '50px' } }}
              eventTracker={eventTracker}
            />
          ) : null
        }

        <div className="mt-10">
          <Notes
            editable={showNotes}
            providerId={serviceCase.provider.id}
            subject={serviceCase.id}
            subjectType="case"
            notesTitle={referralNoteSection ? 'Case Notes' : 'Notes'}
            onAddNote={() => { setShowInteractions(true); }}
          />
        </div>

        {
          referralNoteSection && (
            <div className="case-referral-notes">
              <ReferralNotes
                referrals={referrals}
                providerId={provider.id}
              />
            </div>
          )
        }

        <div className="case-referral-history">
          <ServiceCaseReferralHistory
            serviceCase={serviceCase}
            currentProvider={currentProvider}
            type={type}
          />
        </div>
      </div>
      {
        showContactColumn && (
          <div className="col-md-3">
            <AsideColumn
              contact={serviceCase.person}
              detailObj={serviceCase}
              type={type}
            />
          </div>
        )
      }
      {
        showCopyrightText && (
          <CopyrightText
            header="SNOMED"
            text={SNOMED_TEXT}
          />
        )
      }
    </div>
  );
};

CaseDetailsView.propTypes = {
  attachCaseDocuments: PropTypes.func.isRequired,
  caseAssessments: PropTypes.array,
  caseId: PropTypes.string,
  contactId: PropTypes.string.isRequired,
  currentProvider: PropTypes.object.isRequired,
  currentProviderType: PropTypes.string.isRequired,
  employeeProviderId: PropTypes.string.isRequired,
  enums: PropTypes.object,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  fetchServiceCase: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object,
  isCoordinationGroup: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  refetch: PropTypes.bool,
  removeCaseDocument: PropTypes.func.isRequired,
  renameCaseDocument: PropTypes.func.isRequired,
  serviceCase: PropTypes.shape({
    id: PropTypes.string,
    contact: PropTypes.object,
    outcome: PropTypes.object,
    person: PropTypes.object,
    provider: PropTypes.object,
    service_authorization: PropTypes.object,
    service_type: PropTypes.object,
    state: PropTypes.string,
  }).isRequired,
  showContactColumn: PropTypes.bool,
  type: PropTypes.string.isRequired,
  usePaymentsUserRole: PropTypes.bool,
  referralNoteSection: PropTypes.bool,
  currentProviderId: PropTypes.string.isRequired,
  showCopyrightText: PropTypes.bool,
};

CaseDetailsView.defaultProps = {
  caseAssessments: [],
  caseId: '',
  enums: {},
  history: {},
  refetch: false,
  showContactColumn: true,
  usePaymentsUserRole: false,
  referralNoteSection: false,
  showCopyrightText: false,
};

CaseDetailsView.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    serviceCase,
    session,
    caseAssessments,
    globalState,
  } = state;
  const caseId = _.wget(ownProps, 'params.id');
  const contactId = _.wget(ownProps, 'params.contact_id');
  const currentProvider = _.wget(session, 'currentProvider', {});
  const feeScheduleProvidedServices = _.get(state, `serviceCase.${contactId}[0].feeScheduleProvidedServices`);
  const serviceCaseForContact = _.find(_.get(serviceCase, contactId, []), (sCase) => sCase.id === caseId) || {};
  const restrictContext = restrictToSubmissionContext(state);

  return {
    currentProviderId: globalState.currentProvider.group.id,
    currentProviderType: globalState.currentProvider.group.provider_type,
    caseId,
    caseAssessments,
    contactId,
    currentProvider,
    employeeProviderId: globalState.currentEmployee.provider.id,
    enums: getEnumsFromState(state),
    feeScheduleProvidedServices,
    groupId: session.groupId,
    isCoordinationGroup: session.isCoordinationGroup,
    isFetching: _.get(serviceCase, 'isFetching', false),
    refetch: _.get(serviceCase, 'refetch', false),
    restrictContext,
    serviceCase: serviceCaseForContact,
    usePaymentsUserRole: hasPaymentsUserAccess(state),
    referralNoteSection: hasReferralNoteSection(state),
    showCopyrightText: pays7390CopyrightText(state),
  };
}

export default connect(mapStateToProps, {
  attachCaseDocuments,
  fetchGroupsPrograms,
  fetchServiceCase,
  removeCaseDocument,
  renameCaseDocument,
})(CaseDetailsView);
