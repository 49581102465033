import React from 'react';
import { get, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { InputField, Button, DateField, SelectField } from '@unite-us/ui';
import { useCreateFeeScheduleScreening, useUpdateFeeScheduleScreening, useCreateBillingCondition } from 'components/Backoffice/api/hooks/v1/feeScheduleScreeningHooks';
import { useInvalidateQueries } from 'src/api/APIHooks';
import { validations } from '@unite-us/client-utils';
import RadioField from 'components/Backoffice/form/RadioField';
import Notifier from 'common/helpers/Notifier';
import { Spinner } from 'common/spinners';
import { useFeatureFlag } from 'common/hooks';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import composeValidators from 'src/components/Organization/utils/composeValidators';

const getMinDate = (pickedStartDate, feeScheduleStartsAt) => {
  const pickedStartMoment = moment(pickedStartDate, 'MM/DD/YYYY');
  if (pickedStartMoment.isAfter(moment(feeScheduleStartsAt))) {
    return pickedStartMoment.add('12', 'h').format('MM/DD/YYYY');
  }
  return moment(feeScheduleStartsAt).add('12', 'h').format('MM/DD/YYYY');
};

const conditionTypes = {
  socialCareCoverageCondition: 'BillingConditions::ValidateSocialCareCoverage',
  timespanCondtion: 'BillingConditions::TimespanSinceLastProcedure',
};

// const findConditionByType =
// (bcs, type) => bcs.find((condition) => condition.condition_type === conditionTypes[type]);

const FeeScheduleScreeningForm = ({
 feeScheduleScreening,
  goBack,
//  billingConditions,
  feeScheduleId,
}) => {
  const invalidateQueries = useInvalidateQueries();
  const { data: feeSchedule, isFetching } = useFindFeeSchedule(feeScheduleId);

  const { createRecord: createBC } = useCreateBillingCondition({
    onError: (error) => {
      const errorList = get(error, 'response.data.errors', []);
      const stringNotification = errorList.map((e) => e.title).join(', ');
      Notifier.dispatch(400, `Failed to create billing condition: ${stringNotification}`);
    },
    onSuccess: () => {
      invalidateQueries('billing_condition');
      Notifier.dispatch(200, 'Billing condition successfully created');
    },
  });

  const { createRecord: createFSS } = useCreateFeeScheduleScreening({
    onError: (error) => {
      const errorList = get(error, 'response.data.errors', []);
      const stringNotification = errorList.map((e) => e.title).join(', ');
      Notifier.dispatch(400, `Failed to create fee schedule screening: ${stringNotification}`);
    },
    onSuccess: () => {
      invalidateQueries('fee_schedule_screening');
      Notifier.dispatch(200, 'Fee schedule screning successfully created');
    },
  });

  const onCreate = async (fss) => {
    const objectToSend = {
      ...fss,
      fee_schedule: feeScheduleId,
    };
    const createdFSS = await createFSS(objectToSend);
    if (createdFSS) {
      const fssId = createdFSS?.data?.data?.id;
      if (fss.eligibility_check === 'on') {
        await createBC({
          condition_type: conditionTypes.socialCareCoverageCondition,
          fee_schedule_item: fssId,
          fee_schedule_item_type: 'fee_schedule_screening',
          description: 'eligibility check',
        });
      }
      if (fss.interval_count) {
        await createBC({
          condition_type: conditionTypes.timespanCondtion,
          fee_schedule_item: fssId,
          fee_schedule_item_type: 'fee_schedule_screening',
          description: 'timespan condition',
          condition: {
            interval_count: fss.interval_count,
            interval_type: fss.interval_type,
          },
        });
      }
      goBack();
    }
  };

  const { updateRecord: updateFSS } = useUpdateFeeScheduleScreening({
    onError: (error) => {
      const errorList = get(error, 'response.data.errors', []);
      const stringNotification = errorList.map((e) => e.title).join(', ');
      Notifier.dispatch(400, `Failed to edit fee schedule screening: ${stringNotification}`);
    },
    onSuccess: () => {
      invalidateQueries('fee_schedule_screening');
      Notifier.dispatch(200, 'Fee schedule screning successfully updated');
    },
  });

  const onUpdate = async (payload) => {
    const response = await updateFSS(feeScheduleScreening?.id, payload);
    if (response) {
      goBack();
    }
  };

  const formatValues = (values) => ({
    ...values,
    unit_rate: toInteger(values.unit_rate),
    starts_at: moment(values.starts_at).format('Y-M-D'),
    ends_at: moment(values.ends_at).format('Y-M-D'),
    });

  // const socialCareCoverageCondition = !!findConditionByType(billingConditions, 'socialCareCoverageCondition');
  // const timespanCondition = findConditionByType(billingConditions, 'timespanCondtion');

  const formValues = {
    name: feeScheduleScreening?.name,
    starts_at: feeScheduleScreening?.starts_at && moment(feeScheduleScreening.starts_at),
    ends_at: feeScheduleScreening?.ends_at && moment(feeScheduleScreening.ends_at),
    state: feeScheduleScreening?.state,
    unit_rate: feeScheduleScreening?.unit_rate,
    code_type: feeScheduleScreening?.code_type,
    code: feeScheduleScreening?.code,
    // eligibility_check: socialCareCoverageCondition ? 'on' : 'off',
    // interval_count: timespanCondition?.condition.interval_count,
    // interval_type: timespanCondition?.condition.interval_type,
  };

  // const TIMESPAN_OPTIONS = [
  // { label: 'Day', value: 'day' },
  // { label: 'Month', value: 'month' },
  // { label: 'Year', value: 'year' },
  // ];

  const CODE_TYPE_OPTIONS = [
  { label: 'LOINC', value: 'loinc' },
  ];

  const STATE_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive (Fee Schedule Screenings become inactive once their end date has passed.)', value: 'inactive' },
  { label: 'Archived', value: 'archived' },
  ];

  // const ELIGIBILITY_CHECK_OPTIONS = [
  // { label: 'On', value: 'on' },
  // { label: 'Off', value: 'off' },
  // ];

  const isEditing = feeScheduleScreening?.id;

  const showCopyrightText = useFeatureFlag('pays-7390-copyright-text');
  const codeLabel = showCopyrightText ? 'Code \u00A9' : 'Code';

  if (isFetching) return <Spinner />;

return (
  <Form
    initialValues={formValues}
    onSubmit={(values) => {
      const formattedValues = formatValues(values);
      if (isEditing) { onUpdate(formattedValues); } else { onCreate(formattedValues); }
    }}
    render={({ handleSubmit, values }) => (
      <form
        onSubmit={handleSubmit}
        className={
          'grid grid-cols-12 m-10 p-10 bg-light-border-grey border border-solid rounded border-dark-border-blue'
        }
      >
        <Field
          name="name"
          className="col-span-6 pl-0"
          validate={composeValidators(validations.isRequired)}
        >
          {(props) => (
            <InputField
              id="fee-schedule-screning__name"
              label="Name"
              required
              dataTestId="fss-name-field"
              {...props}
            />
          )}
        </Field>
        <div className="col-span-6" />

        <Field
          className="col-span-6 pl-0"
          name="starts_at"
          validate={(value) => {
            if (value && values.ends_at && moment(value).isSameOrAfter(values.ends_at)) {
              return 'Start date should be before end date';
            }
            if (value && isEditing && moment(value).isBefore(feeScheduleScreening.starts_at)) {
              return `Start date should be after ${moment(feeScheduleScreening.starts_at).format('MM/DD/YYYY')}`;
            }
            return validations.isRequired(value);
          }}
        >
          {(props) => (
            <DateField
              className="col-span-6 pl-0"
              id="fee-schedule-program__starts-at-field"
              label="Start Date"
              minDate={moment().startOf('day').subtract(1, 'day')}
              required
              valueFormat="MM/DD/YYYY"
              {...props}
            />
          )}
        </Field>
        <Field
          name="ends_at"
          validate={(value) => {
            if (value && values.starts_at && moment(value).isSameOrBefore(values.starts_at)) {
              return 'End date should be after start date';
            }
            if (value && moment(value).isSameOrAfter(feeSchedule.ends_at)) {
              return `End date should be before fee schedule end date
              ${moment(feeSchedule.ends_at).format('MM/DD/YYYY')}`;
            }
            return validations.isRequired(value);
          }}
        >
          {(props) => (
            <DateField
              className="col-span-6"
              id="fee-schedule-program__ends-at-field"
              label="End Date"
              minDate={getMinDate(values.starts_at, moment())}
              required
              valueFormat="MM/DD/YYYY"
              {...props}
            />
          )}
        </Field>
        <Field
          name="state"
          className="col-span-12 px-0"
          defaultValue={'active'}
        >
          {(props) => (
            <RadioField
              className="flex-wrap px-0"
              id="fee-schedule-screning__state"
              label="Status"
              required
              options={STATE_OPTIONS}
              {...props}
            />
          )}
        </Field>

        <Field
          name="unit_rate"
          className="col-span-6 pl-0"
          validate={composeValidators(validations.isRequired)}
        >
          {(props) => (
            <InputField
              className="flex-wrap px-0"
              id="fee-schedule-screning__unit-rate"
              label="Unit rate"
              type="number"
              required
              {...props}
            />
          )}
        </Field>
        <div className="col-span-6" />
        <Field
          name="code_type"
          className="col-span-6 pl-0"
          defaultValue={'loinc'}
        >
          {(props) => (
            <SelectField
              searchEnabled={false}
              className="col-span-6"
              id="fee-schedule-screning__code-type"
              label="Code type"
              options={CODE_TYPE_OPTIONS}
              placeholder="Select type"
              required
              {...props}
            />
            )}
        </Field>
        <Field
          name="code"
          className="col-span-6 pl-0"
          validate={composeValidators(validations.isRequired)}
        >
          {(props) => (
            <InputField
              className="col-span-6"
              id="fee-schedule-screning__code"
              label={codeLabel}
              required
              {...props}
            />
          )}
        </Field>

        <hr className="col-span-12 mb-4" />

        {/* <div className="col-span-12 pl-0 mb-2">
          <h3 className="font-bold mb-1">Billing Conditions</h3>
          <div>Specifies the interval of time between screenings.</div>
        </div>
        <Field
          name="interval_count"
          className="col-span-6 pl-0"
        >
          {(props) => (
            <InputField
              id="fee-schedule-screning__interval-count"
              label="Value"
              type="number"
              disabled={isEditing}
              {...props}
            />
          )}
        </Field>

        <Field
          name="interval_type"
          className="col-span-6 pl-0"
          defaultValue={'day'}
        >
          {(props) => (
            <SelectField
              searchEnabled={false}
              className="col-span-6"
              id="fee-schedule-screning__interval-type"
              label="Time Period"
              options={TIMESPAN_OPTIONS}
              placeholder="Select type"
              disabled={isEditing}
              {...props}
            />
            )}
        </Field>

        <Field
          name="eligibility_check"
          className="col-span-12 pl-0"
        >
          {(props) => (
            <RadioField
              className="flex-wrap px-0"
              id="fee-schedule-screning__eligibility-check"
              label="Eligibility Check"
              hint="Check if the client has active SCC for the dates listed on the screening."
              options={ELIGIBILITY_CHECK_OPTIONS}
              disabled={isEditing}
              {...props}
            />
          )}
        </Field> */}

        <div className="col-span-12 pl-0 flex items-start">
          <Button
            label="Cancel"
            onClick={() => goBack()}
            data-testid="cancel-add-fs-program-btn"
          />
          <Button
            className="mr-5 ml-3"
            type="submit"
            label={isEditing ? 'Update' : 'Create'}
            primary
            data-testid="add-fs-program-btn"
          />
        </div>
      </form>
    )}
  />
  );
};
FeeScheduleScreeningForm.propTypes = {
  feeScheduleScreening: PropTypes.object,
  goBack: PropTypes.func.isRequired,
  billingConditions: PropTypes.object,
  feeScheduleId: PropTypes.string.isRequired,
};

FeeScheduleScreeningForm.defaultProps = {
  feeScheduleScreening: {},
  billingConditions: [],
};

export default FeeScheduleScreeningForm;
