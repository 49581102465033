var PAYMENT_TYPES = {
  fee_for_service: 'Fee for service',
  per_member_per_month: 'Per member per month',
  cost_based_reimbursement: 'Cost-based reimbursement up to a cap',
  per_diem: 'Per diem',
  funds_distributed: 'Funds distributed',
  time_based: 'Time-based service'
};
var isCostPaymentType = function isCostPaymentType(paymentType) {
  return ['cost_based_reimbursement', 'funds_distributed'].includes(paymentType);
};
export default {
  PAYMENT_TYPES: PAYMENT_TYPES,
  isCostPaymentType: isCostPaymentType
};