import React from 'react';
import PropTypes from 'prop-types';
import { flow } from 'lodash';
import { useFeatureFlag } from 'common/hooks';
import {
  useFindFeeScheduleScreening,
  // useFindBillingConditions,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleScreeningHooks';
import {
  useFindFeeSchedule,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import { BackButton } from 'common/buttons';
import Breadcrumbs from 'common/Breadcrumbs';
import { Spinner } from 'common/spinners';
import { LOINC_TEXT } from 'common/messageConstants';
import { browserHistory } from 'common/utils/browserHistory';
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';
import CopyrightText from 'common/Disclaimer/CopyrightText';
import FeeScheduleScreeningProgramsTable from './FeeScheduleScreeningProgramsTable';
import FeeScheduleScreeningForm from './FeeScheduleScreeningForm';
import FeeScheduleScreeningDetail from './FeeScheduleScreeningDetail';
import FeeScheduleScreeningSubHeader from './FeeScheduleScreeningSubHeader';
import FeeScheduleScreeningCboTable from './FeeScheduleScreeningCboTable';

const FeeScheduleScreening = ({ params, location }) => {
  const tabUrl = location?.pathname.split('/').pop();
  const feeScheduleId = params.fee_schedule_id;
  const feeScheduleScreeningId = params.fee_schedule_screening_id;
  // const { data: billingConditions } = useFindBillingConditions(feeScheduleScreeningId);
  const { data: feeSchedule, isLoading } = useFindFeeSchedule(feeScheduleId);
  const { data: feeScheduleScreening, isLoading: isLoadingFSS } = useFindFeeScheduleScreening(feeScheduleScreeningId);

  const showCopyrightText = useFeatureFlag('pays-7390-copyright-text');

  const feeScheduleLabel = !feeSchedule?.name ? 'Loading...' : feeSchedule.name;
  const feeScheduleScreeningLabel = !feeScheduleScreening?.name ? 'Loading Screening...' :
    feeScheduleScreening.name;

  const crumbs = [
    { label: 'Fee Schedules', href: '/backoffice/fee_schedules' },
    { label: feeScheduleLabel, href: `/backoffice/fee_schedules/${feeSchedule.id}` },
    { label: feeScheduleScreeningLabel },
  ];

  const backToFeeScheduleScreenings = () => {
    let pathname = '';
    switch (tabUrl) {
      case 'edit':
        pathname = `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreeningId}`;
        break;
      case 'programs':
        pathname = `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreeningId}`;
        break;
      default:
        pathname = `/backoffice/fee_schedules/${feeScheduleId}/fee-schedule-screenings`;
        break;
    }

    browserHistory.push({
      pathname,
    });
  };

  const ActiveTab = () => {
    switch (tabUrl) {
      case 'cbo-table':
        return <FeeScheduleScreeningCboTable feeScheduleScreening={feeScheduleScreening} />;
      case 'edit':
        return (
          <FeeScheduleScreeningForm
            feeScheduleScreening={feeScheduleScreening}
            goBack={backToFeeScheduleScreenings}
            // billingConditions={billingConditions}
            feeScheduleId={feeScheduleId}
          />
        );
      case 'programs':
        return <FeeScheduleScreeningProgramsTable feeScheduleScreening={feeScheduleScreening} />;
      default:
        return (
          <FeeScheduleScreeningDetail
            feeScheduleScreening={feeScheduleScreening}
            showCopyrightText={showCopyrightText}
          />
        );
    }
  };

  const isEditing = tabUrl === 'edit';

  if (isLoading || isLoadingFSS) return <Spinner />;
  return (
    <div className="detail-view" data-testid="fee-schedule-screening">
      <div className="flex p-4 justify-start items-center bg-white border-b border-solid border-dark-border-blue">
        <div>
          <BackButton navigateTo={backToFeeScheduleScreenings} />
        </div>
        <div className="px-5">
          {isEditing ? <h1 className="pl-6 font-bold">Edit Fee Schedule Screening</h1> : (
            <Breadcrumbs className="bread-crumbs" crumbs={crumbs} />
          )}
        </div>
      </div>
      {!isEditing && (
        <div className="bg-white border-b border-solid border-dark-border-blue mb-0">
          <FeeScheduleScreeningSubHeader
            feeScheduleScreening={feeScheduleScreening}
            feeSchedule={feeSchedule}
          />
        </div>
      )}
      <ActiveTab />
      {showCopyrightText &&
        (
          <CopyrightText
            header="LOINC"
            text={LOINC_TEXT}
          />
        )}
    </div>
  );
};

FeeScheduleScreening.propTypes = {
  params: PropTypes.shape({
    fee_schedule_id: PropTypes.string.isRequired,
    fee_schedule_screening_id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default flow(FeatureFlagContainer)(FeeScheduleScreening);
