import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from 'common/spinners';
import _ from 'lodash';
import Notifier from 'common/helpers/Notifier';
import {
  Button,
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/ui';
import { hasFeatureRole } from 'common/utils/Roles/hasFeatureRole';
import { fetchServiceCases } from 'actions/Case/Contact/Group';
import { findContactDocuments } from 'actions/Document';
import {
  fetchContactDocuments,
  renameContactDocument,
  removeDocumentFromContactResources,
  uploadAndAttachContactDocuments,
} from 'actions/Document/Contact/Group';
import RenameDocumentDialog from 'common/form/DocumentUploader/components/RenameDocumentDialog';
import UploadAndAttachDocuments from 'common/form/DocumentUploader/components/UploadAndAttachDocuments';
import ContactDocuments from 'src/components/Facesheet/Uploads/components/ContactDocuments';
import { cl1351RestrictedDocumentUpload } from 'src/common/utils/FeatureFlags/flags';
import './Uploads.scss';

export class FacesheetUploads extends Component {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.onRenameDocument = this.onRenameDocument.bind(this);
    this.openRenameDialog = this.openRenameDialog.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchServiceCases = this.fetchServiceCases.bind(this);
    this.removeFromContact = this.removeFromContact.bind(this);
    this.saveUploadedDocuments = this.saveUploadedDocuments.bind(this);

    this.state = {
      editMode: false,
      documentSearch: props.searchedTerm,
      loading: false,
      currentDocument: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contactId } = this.props;
    if (nextProps.contactId !== contactId) {
      const { groupId } = this.props;
      this.props.fetchContactDocuments({ groupId, contactId: nextProps.contactId })
        .then(() => {
          this.props.findContactDocuments(nextProps.contactId, nextProps.searchedTerm);
          this.setState({ documentSearch: nextProps.searchedTerm });
        });
    }
  }

  onRenameDocument(doc) {
    const { contactId, groupId } = this.props;
    this.props.renameContactDocument({
      contactId,
      documentId: doc.id,
      groupId,
      title: doc.title,
    });
  }

  onInputChange(documentSearch) {
    this.setState({ documentSearch });
  }

  fetchServiceCases() {
    const { groupId, contact = {} } = this.props;
    if (_.isEmpty(this.props.contactCases)) {
      return this.props.fetchServiceCases(groupId, contact.id);
    }
    return null;
  }

  openRenameDialog(doc) {
    this.setState({ editMode: true, currentDocument: doc }, () => {
      this.renameDocumentDialog.form.getWrappedInstance().dialog.openDialog()
        .then(() => this.setState({ editMode: false }));
    });
  }

  fetchData() {
    const { contactId, groupId } = this.props;
    const { documentSearch } = this.state;
    const promises = [
      this.fetchServiceCases(),
      this.props.fetchContactDocuments({ groupId, contactId }),
    ];

    this.setState({ loading: true });
    Promise.all(promises).then(() => {
      this.props.findContactDocuments(contactId, documentSearch).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  removeFromContact(doc = {}) {
    const { contactId } = this.props;
    return this.props.removeDocumentFromContactResources({
      contactId,
      documentId: doc.id,
    });
  }

  saveUploadedDocuments({ uploadedDocuments, privateToOrg, privateToRole }) {
    const { contactId } = this.props;
    const additionalData = [
      {
        key: 'privateToOrg',
        value: privateToOrg,
      },
      {
        key: 'privateToRole',
        value: privateToRole,
      },
    ];
    if (!_.isEmpty(uploadedDocuments)) {
      this.props.uploadAndAttachContactDocuments({
        contactId,
        uploadedDocuments,
        additionalData,
      }).then(() => {
        this.fetchData();
        Notifier.dispatch('success', 'Documents Saved');
      });
    }
  }

  render() {
    const {
      documents,
      styles,
      hasSocialCareNavigationRole,
      hasRestrictedUploadFlag,
    } = this.props;
    const {
      currentDocument,
      editMode,
      loading,
    } = this.state;

    const WARNING_TEXT = hasRestrictedUploadFlag ?
      [
        'Reminder: Unless you limit document visibility, all providers working with',
        'a client can access documents uploaded here. To upload documents specific to a referral',
        'or case (especially those with PHI), go to that referral/case record.'].join(' ') :
      [
        'Reminder: Documents uploaded to the Upload Tab are accessible',
        'by all providers working with a client. Please only upload documents specific to a',
        'referral or case (especially PHI) on that case/referral record.'].join(' ');

    return (
      <BaseCard className="uploads">
        <BaseCardHeader title="Documents">
          <UploadAndAttachDocuments
            anchorEl={(
              <Button
                id="upload-document-btn"
                label="Upload Document"
                className="uploads__upload-btn"
                labelStyle={_.get(styles, 'uploadDocButton', {})}
                primary
              />
            )}
            dropzoneName="contactDocuments"
            onAttachDocuments={this.saveUploadedDocuments}
            warningText={WARNING_TEXT}
            showDocumentVisibility
            hasSocialCareNavigationRole={hasSocialCareNavigationRole}
          />
        </BaseCardHeader>
        <BaseCardBody withPadding>
          {
            loading ? <Spinner /> : (
              <div className="uploads__body">
                <ContactDocuments
                  documents={documents.data || []}
                  onRemove={this.removeFromContact}
                  onRename={this.openRenameDialog}
                />

                <RenameDocumentDialog
                  doc={currentDocument}
                  ref={(c) => { this.renameDocumentDialog = c; }}
                  onRenameDocument={this.onRenameDocument}
                  dialogOpen={editMode}
                />
              </div>
            )
          }
        </BaseCardBody>
      </BaseCard>
    );
  }
}

FacesheetUploads.propTypes = {
  contact: PropTypes.object.isRequired,
  contactCases: PropTypes.array.isRequired,
  contactId: PropTypes.string.isRequired,
  documents: PropTypes.shape({
    data: PropTypes.array,
  }),
  fetchContactDocuments: PropTypes.func.isRequired,
  fetchServiceCases: PropTypes.func.isRequired,
  findContactDocuments: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  removeDocumentFromContactResources: PropTypes.func.isRequired,
  renameContactDocument: PropTypes.func.isRequired,
  searchedTerm: PropTypes.string.isRequired,
  styles: PropTypes.object,
  uploadAndAttachContactDocuments: PropTypes.func.isRequired,
  hasSocialCareNavigationRole: PropTypes.bool,
  hasRestrictedUploadFlag: PropTypes.bool,
};

FacesheetUploads.defaultProps = {
  documents: {
    data: [],
  },
  styles: {
    uploadDocButton: {
      textTransform: 'none',
    },
  },
  hasSocialCareNavigationRole: false,
  hasRestrictedUploadFlag: false,
};

/**
 * @param { object } - contactDocuments - data from the contact Documents
 * @param { object } - OwnProps - Props receieved from the parent (used to get the contactId)
 * @return { object } - document object with its array of documents && paging object respectively
 */
const generateDocuments = ({ contactDocuments, contactId, ownProps }) => {
  if (_.isEmpty(contactDocuments)) {
    return {};
  }
  if (contactDocuments[contactId] && contactDocuments[contactId].searchedWord) {
    const filteredDocumentsObject = _.merge({}, contactDocuments);
    const documentSearchRegex = new RegExp(contactDocuments[contactId].searchedWord, 'gi');
    const filteredDocuments = contactDocuments[contactId].data.filter((doc) => (doc.title.match(documentSearchRegex)));
    filteredDocumentsObject[contactId].data = filteredDocuments;
    return filteredDocuments.length > 0 ? filteredDocumentsObject[contactId] : contactDocuments[contactId];
  }
  return contactDocuments[ownProps.params.id];
};

function mapStateToProps(state, ownProps) {
  const { contactDocuments } = state;

  const contacts = state.contacts.contacts;
  const contactId = _.get(ownProps, 'params.id');
  const contact = _.find(contacts, { id: contactId });

  const filteredDocuments = generateDocuments({
    contactDocuments,
    contactId,
    ownProps,
  });

  const contactCaseObj = _.get(state.serviceCase, contactId);
  const contactCases = _.get(contactCaseObj, 'data', []);

  return {
    groupId: state.session.groupId,
    documents: filteredDocuments,
    contact,
    contactId,
    searchedWord: state.contactDocuments.searchedWord,
    searchedTerm: _.get(ownProps, 'location.query.s', ''),
    contactCases,
    hasSocialCareNavigationRole: hasFeatureRole({
      employeeRoles: _.get(state, 'globalState.currentEmployee.roles', []),
      targetRoleKey: 'social_care_navigation',
    }),
    hasRestrictedUploadFlag: cl1351RestrictedDocumentUpload(state),
  };
}

export default connect(mapStateToProps, {
  fetchContactDocuments,
  fetchServiceCases,
  findContactDocuments,
  removeDocumentFromContactResources,
  renameContactDocument,
  uploadAndAttachContactDocuments,
})(FacesheetUploads);
