import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import FileUploadV2 from 'common/form/FileUploadV2/FileUpload';
import { generateUUID } from 'common/utils/utils';
import {
  addFileForSubmit,
  clearFileUpload,
  destroyDropzone,
  initDropzone,
} from './actions/FileUpload';
import {
  getDataURI,
  setDropzoneInputId,
} from './utils';
import { DROPZONE_INPUT_ID } from './constants';

export class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: [],
      rejectedFiles: {
        filesTooBig: [],
        filesWrongType: [],
        filesTooBigAndWrongType: [],
      },
    };

    this.handleUploadedFiles = this.handleUploadedFiles.bind(this);
  }

  UNSAFE_componentWillMount() {
    const config = {
      name: this.props.name,
      multiple: this.props.multiple || false,
    };
    this.props.initDropzone(config);
  }

  componentDidMount() {
    const { file = {}, name } = this.props;
    // Set the dropzone input ID so we can add a label (accessibility requirement)
    setDropzoneInputId({ dropzoneId: name, inputName: file.name });
  }

  componentDidUpdate(prevProps) {
    const relevantPrevProps = _.pick(prevProps.fileupload, Object.keys(this.props.fileupload));
    const sameKeys = _.isEqual(Object.keys(this.props.fileupload), Object.keys(relevantPrevProps));
    const noFileupload = _.isEmpty(this.props.fileupload) && !_.isEmpty(prevProps.fileupload);

    if ((sameKeys && !_.isEqual(this.props.fileupload, relevantPrevProps)) || noFileupload) {
      const documents = _.get(this.props.fileupload, [this.props.name, 'files'], []);

      // This format is needed since in src/common/form/FileUploadV2/FileUpload.jsx:160 it expects this shape,
      // otherwise it will throw an error
      const uploadedFilesFormatted = documents.map((document) => ({
        file: {
          name: document.name,
          size: document.size,
          type: document.type,
        },
        preview: URL.createObjectURL(document),
        tempId: generateUUID(),
      }));

      this.setState({
        uploadedFiles: uploadedFilesFormatted,
        rejectedFiles: {
          filesTooBig: [],
          filesWrongType: [],
          filesTooBigAndWrongType: [],
        },
      });
    }
  }

  componentWillUnmount() {
    const { persistOnUnmount } = this.props;
    if (!persistOnUnmount) {
      this.props.destroyDropzone(this.props.name);
    }
  }

  handleUploadedFiles(filesToUpload) {
    const { name } = this.props;
    this.props.clearFileUpload(name);
    this.setState({ uploadedFiles: filesToUpload });
    const files = filesToUpload.map((fileToUpload) => _.merge(fileToUpload.file, { preview: fileToUpload.preview }));

    const promises = files.map((currentFile) => (
      getDataURI(currentFile).then((data) => {
        const fileWithData = _.merge(currentFile, { data, success: true });
        this.props.addFileForSubmit(name, fileWithData, this.props.multiple);
      })
    ));

    this.props.file.onChange(files);

    return Promise.all(promises);
  }

  render() {
    const {
      children,
      multiple,
      name,
    } = this.props;

    const { uploadedFiles, rejectedFiles } = this.state;

    return (
      <div id={name} className="my-2">
        <label htmlFor={DROPZONE_INPUT_ID} className="sr-only">
          Upload Files
        </label>
        <FileUploadV2
          multiple={multiple}
          rejectedFiles={rejectedFiles}
          setRejectedFiles={(files) => this.setState({ rejectedFiles: files })}
          setUploadedFiles={this.handleUploadedFiles}
          uploadedFiles={uploadedFiles}
          className="bg-medium-fill-grey border-dark-border-blue"
        >
          {children}
        </FileUploadV2>
      </div>
    );
  }
}

FileUpload.propTypes = {
  addFileForSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  clearFileUpload: PropTypes.func.isRequired,
  destroyDropzone: PropTypes.func.isRequired,
  file: PropTypes.object,
  fileupload: PropTypes.object,
  initDropzone: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  persistOnUnmount: PropTypes.bool.isRequired,
};

FileUpload.defaultProps = {
  children: null,
  file: {},
  fileupload: {},
  multiple: false,
};

function mapStateToProps(state) {
  const { fileupload } = state;
  return { fileupload };
}

export default connect(mapStateToProps, {
  initDropzone,
  destroyDropzone,
  addFileForSubmit,
  clearFileUpload,
})(FileUpload);
