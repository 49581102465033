import React from 'react';
import PropTypes from 'prop-types';

const CopyrightText = ({ header, text }) => (
  <div data-testid="copyright-text" className="col-12 mt-10 gap-10 h-fit">
    <hr />
    <div className="flex justify-center items-center">
      <div className="w-2/3 my-8">
        <div className="font-bold my-4 capitalize">
          &copy; {header}
        </div>
        <div>
          {text}
        </div>
      </div>
    </div>
  </div>
);

CopyrightText.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CopyrightText;
