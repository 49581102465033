import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useFeatureFlag } from 'common/hooks';
import { useFind } from 'src/api/APIHooks';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { Card } from 'src/common/Card';
import { Spinner } from 'src/common/spinners';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import {
  ServiceAuthorizationTable,
  ServiceAuthorizationPagination,
  FilterSelectors,
} from '../components';
import {
  NoServiceAuthorizationsFoundCard,
} from '../components/NoAuthorizationsMessage/NoServiceAuthorizationsFoundCard';
import {
  useServiceAuthorizationPagination,
  useServiceAuthorizationFilters,
} from '../hooks';

export const ServiceAuthorizationWorkqueue = ({
  authorizationStatus,
  onlyConsentedPeople,
  showTimeInQueue,
}) => {
  const paginationProps = useServiceAuthorizationPagination();
  const currentPayerId = useCurrentPayerId();
  const showFilterbarFF = useFeatureFlag('pays-3535-service-authorization-wq-filterbar');
  const useSparseFieldsForPeopleFF = useFeatureFlag('PAYS-7267-include-internal-nonconsent');
  const {
    filterValues,
    filterOptions,
    isFiltered,
    setClientNameFilter,
    setStatusFilter,
    setRequestIdFilter,
    setContractedProgramFilter,
    resetFilters,
    setContractedProgramName,
    setPrimaryHealthInsuranceMemberIdFilter,
  } = useServiceAuthorizationFilters({
    payerId: currentPayerId,
    authorizationStatus,
  });

  const state = filterValues.status === AUTHORIZATION_STATUSES.autoAccepted ?
  AUTHORIZATION_STATUSES.accepted : filterValues.status;

  const authorizationFilters = {
    state: authorizationStatus,
    'insurance.plan.payer': currentPayerId,
    ...(onlyConsentedPeople ? { 'person.consent.state': 'accepted' } : {}),
    ...(filterValues.clientName ? { 'person.query': filterValues.clientName } : {}),
    ...(filterValues.status ? { state } : {}),
    ...(filterValues.status === AUTHORIZATION_STATUSES.autoAccepted ? { auto_approved: true } : {}),
    ...(filterValues.requestId ? { short_id: filterValues.requestId } : {}),
    ...(filterValues.contractedProgram ? { fee_schedule_program: filterValues.contractedProgram } : {}),
    ...(filterValues.primaryHealthInsuranceMemberId ?
      { 'insurance.primary_health_insurance.external_member_id_like': filterValues.primaryHealthInsuranceMemberId } :
      {}
    ),
    ...(useSparseFieldsForPeopleFF ? { include_internal_nonconsent: true } : {}),
  };

  const { isLoading: isAuthorizationsLoading, data } = useFind(
    'service_authorization',
    authorizationFilters,
    {
      page: {
        number: paginationProps.pageNumber,
        size: paginationProps.pageSize,
      },
      queryConfig: {
        enabled: !!currentPayerId,
        placeholderData: undefined,
      },
      ...(useSparseFieldsForPeopleFF && {
        customParams: {
          include: 'person',
          fields: {
            person: 'first_name,last_name',
          },
        },
      }),
    },
  );

  const authorizationRequestsData = data?.data?.data ?? [];
  const includedPeople = data?.data?.included ?? [];
  const includedPeopleLookup = new Map(includedPeople.map((person) => [person.id, person]));

  const authorizationRequests = useSparseFieldsForPeopleFF ?
    authorizationRequestsData.map((request) => {
      const requestedPersonId = request.person.id;
      const includedPerson = requestedPersonId ? includedPeopleLookup.get(requestedPersonId) : null;
      return {
        ...request,
        person: includedPerson ? {
            id: includedPerson.id,
            ...includedPerson.attributes,
          } : {},
        };
    }) : authorizationRequestsData;

  const totalItemCount = get(data, 'data.paging.total_count', 0);
  const totalPageCount = get(data, 'data.paging.total_pages', 0);

  return (
    <div className="divide-y divide-solid divide-dark-border-blue">
      <div className="p-6 pt-8 bg-white">
        <h1 className="text-text-blue font-medium-font text-2xl" data-testid="authorization-requests-header">
          Authorization Requests
        </h1>
      </div>
      {showFilterbarFF && (
        <div className="bg-light-border-grey px-6 py-4">
          <FilterSelectors
            filterValues={filterValues}
            filterOptions={filterOptions}
            setClientNameFilter={setClientNameFilter}
            setStatusFilter={setStatusFilter}
            setRequestIdFilter={setRequestIdFilter}
            setContractedProgramFilter={setContractedProgramFilter}
            setContractedProgramName={setContractedProgramName}
            setPrimaryHealthInsuranceMemberIdFilter={setPrimaryHealthInsuranceMemberIdFilter}
            resetFilters={resetFilters}
            authorizationStatus={authorizationStatus}
          />
        </div>
      )}
      <div className="px-6 py-4">
        {/* eslint-disable no-nested-ternary */}
        {isAuthorizationsLoading ? (
          <div role="alert"><Spinner /></div>
        ) : (
          !isEmpty(authorizationRequests) ? (
            <>
              <Card className="mb-6">
                <ServiceAuthorizationTable
                  authorizationRequests={authorizationRequests}
                  showTimeInQueue={showTimeInQueue}
                />
              </Card>
              <ServiceAuthorizationPagination
                {...paginationProps}
                totalItemCount={totalItemCount}
                totalPageCount={totalPageCount}
              />
            </>
          ) : (
            <NoServiceAuthorizationsFoundCard
              isFiltered={isFiltered}
              resetFilters={resetFilters}
              status={authorizationStatus}
            />
          )
        )}
        {/* eslint-enable no-nested-ternary */}
      </div>
    </div>
  );
};

ServiceAuthorizationWorkqueue.propTypes = {
  authorizationStatus: PropTypes.string.isRequired,
  showTimeInQueue: PropTypes.bool,
  onlyConsentedPeople: PropTypes.bool,
};

ServiceAuthorizationWorkqueue.defaultProps = {
  onlyConsentedPeople: false,
  showTimeInQueue: false,
};

export default ServiceAuthorizationWorkqueue;
