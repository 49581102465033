import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CheckBoxField } from '@unite-us/app-components';
import { cl1351RestrictedDocumentUpload } from 'src/common/utils/FeatureFlags/flags';

export const DocumentVisibility = ({
  privateToOrg,
  privateToRole,
  hasSocialCareNavigationRole,
  hasRestrictedUploadFlag,
}) => {
  if (!hasRestrictedUploadFlag) return null;

  return (
    <div className="document-visibility mt-5 pt-5 border-t border-solid border-dark-border-grey">
      <h3 className="mb-3 font-medium">Document Visibility</h3>
      <div className="mb-3">
        <CheckBoxField
          id="private-to-org"
          label="Private to my organization"
          checked={privateToOrg.value}
          onChange={privateToOrg.onChange}
          hint="Select this check box so only users of your organization can see these documents."
          style={{
            label: 'normal-case',
          }}
        />
      </div>

      {hasSocialCareNavigationRole && (
        <div className="mb-3">
          <CheckBoxField
            id="private-to-role"
            label="Private to my role"
            checked={privateToRole.value}
            onChange={privateToRole.onChange}
            hint="Select this check box so only users with your role can see these documents."
            style={{
              label: 'normal-case',
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasRestrictedUploadFlag: cl1351RestrictedDocumentUpload(state),
});

DocumentVisibility.propTypes = {
  privateToOrg: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  privateToRole: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  hasSocialCareNavigationRole: PropTypes.bool,
  hasRestrictedUploadFlag: PropTypes.bool,
};

DocumentVisibility.defaultProps = {
  privateToOrg: { value: false, onChange: () => {} },
  privateToRole: { value: false, onChange: () => {} },
  hasSocialCareNavigationRole: false,
  hasRestrictedUploadFlag: false,
};

export default connect(mapStateToProps)(DocumentVisibility);
